import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;

  @media (${props => props.theme.bp.mobileL}) {
    font-size: 2.4rem;
  }
`;

export const SectionHeader = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: var(--orange);
  letter-spacing: 2.4px;
  padding-bottom: 26px;
  font-weight: bold;
`;
