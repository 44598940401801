import React from 'react';

export const SVGShapeHeader = () => (
  <svg preserveAspectRatio='none' width='1368' height='156' viewBox='0 0 1368 156'>
    <path
      d='M.5 6.059s216.074-11.827 367.522-.912S521.655 55.409 608.1 55.409 731.324-.544 873.709 6.059s167.721 65.809 302.127 70.074 192.043-57.224 192.043-57.224v137.014H.5z'
      fill='currentColor'
      stroke='currentColor'
    />
  </svg>
);
